import React, { useEffect, useState } from "react";
import styles from "./CreateImprovement.module.css";
import layout from "../../../../Modules/layout.module.css";
import Button from "../../../../Components/Utils/Button/Button";
import Form from "../../../../Components/Utils/Form/Form";
import CreateImprovementQuestionSection from "../../../../Components/CreateImprovement/CreateImprovementQuestionSection/CreateImprovementQuestionSection";
import FloatingLabelInput from "../../../../Components/Utils/Form/FloatingLabelInput/FloatingLabelInput";
import FloatingLabelSelect from "../../../../Components/Utils/Form/FloatingLabelSelect/FloatingLabelSelect";
import {
  GetImprovementPlanDisplayInfoByClientAndUser,
  CreateEvaluateImprovement,
} from "../../../../API/Improve/Improve";
import { useSelector } from "react-redux";
import { RootState } from "../../../../Redux/Store/Store";
import { FloatingLabelSelectOption } from "../../../../Types/Form/FloatingLabelSelectOption";
import Popup from "../../../../Components/Utils/Popup/Popup";
import { CreateEvaluateImprovementRequest } from "../../../../Types/Evaluate/CreateEvaluateImprovementRequest";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Import Toastify styles


const CreateImprovement = () => {
  const [improvementPlansOptions, setImprovementPlansOptions] = useState<
    FloatingLabelSelectOption[]
  >([]);

  const reduxAuth = useSelector((state: RootState) => state.auth);
  const reduxQ = useSelector((state: RootState) => state.questionnaire);
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const [improvementData, setImprovementData] =
    useState<CreateEvaluateImprovementRequest>({
      title: "",
      improvementPlanId: "",
      evaluateAreaId: reduxQ.questionnaire?.id,
      questionGroups: [],
    });

    useEffect(() => {
      console.log(improvementData)
    }, [improvementData])

  useEffect(() => {
    const fetchImprovementPlanData = async () => {
      try {
        const res = await GetImprovementPlanDisplayInfoByClientAndUser(
          process.env.REACT_APP_CLIENT_ID!,
          reduxAuth.user!.id
        );

        const options: FloatingLabelSelectOption[] = res.map((item: any) => ({
          value: item.id,
          label: item.title,
        }));

        console.log(options[0].value);

        setImprovementPlansOptions(options);
      } catch (error) {
        console.error("Failed to fetch improvement plans:", error);
      }
    };

    fetchImprovementPlanData();
  }, [reduxAuth.user]);

  const handleTitleChange = (value: string) => {
    setImprovementData((prevState) => {
      return {
        ...prevState,
        title: value,
      };
    });
  };

  const handleImprovementPlanSelect = (value: string) => {
    setImprovementData((prevState) => {
      return {
        ...prevState,
        improvementPlanId: value,
      };
    });
  };
  

  const handleSubmit = async (): Promise<void> => {
    try {
      // Attempt to create the improvement
      await CreateEvaluateImprovement(improvementData);
  
      // On success, show a success toast
      toast.success("Improvement created successfully!");
  
      // Reset Improvement Data
      setImprovementData({
        title: "",
        improvementPlanId: "",
        evaluateAreaId: reduxQ.questionnaire?.id,
        questionGroups: [],
      });
    } catch (error) {
      // On error, show an error toast
      toast.error("Failed to create improvement. Please try again.");
      console.error("Error creating improvement:", error);
    }
  };
  

  return (
    <div className={layout.column}>
      <div className={layout.rowSpan}>
        <h2>Improvement Plan</h2>
      </div>
      <Form>
        <FloatingLabelInput
          placeholder="Add Objective Title..."
          label="What is the title of your objective? (e.g. Complete training for good practice)"
          required
          value={improvementData.title}
          onChange={(e) => handleTitleChange(e.target.value)}
        />
        <FloatingLabelSelect
          label="Which Improvement plan would you like to add the objective to?"
          options={improvementPlansOptions}
          required
          value={improvementData.improvementPlanId}
          onChange={(e) => handleImprovementPlanSelect(e.target.value)}
        />
      </Form>
      {reduxQ.questionnaire?.questionnaireSections.map((item) => (
        <CreateImprovementQuestionSection
          key={item.title}
          title={item.title}
          criteria={item.criteria}
          id={item.id}
          improvementData={improvementData}
          setImprovementData={setImprovementData}
        />
      ))}
      <div className={`${layout.rowSpan} ${layout.justifyFlexEnd}`}>
        <Button clickHandler={handleSubmit} autoDisable>Create Improvement</Button>
      </div>
    </div>
  );
};

export default CreateImprovement;
