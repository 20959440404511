import React, { useEffect, useState } from "react";
import styles from "./PagedCardCollection.module.css";
import { AreaDisplayInfo } from "../../../../Types/AreaDisplayInfo/AreaDisplayInfo";
import CardCollection from "../CardCollection";
import Paging from "../../Paging/Paging";
import Skeleton from "../../Skeleton/Skeleton";
import {
  GetArchivedPagedEvaluateAreaDisplayInfosByClientAndUser,
  GetPagedEvaluateAreaDisplayInfosByClientAndUser,
} from "../../../../Redux/Store/Slices/area";
import { PagedRequest } from "../../../../Types/Paging/PagedRequest";
import { useAppDispatch, useAppSelector } from "../../../../Redux/Store/Store";
import { toast } from "react-toastify";

type PagedCardCollectionProps = {
  archived: boolean;
};

const PagedCardCollection = ({ archived }: PagedCardCollectionProps) => {
  const dispatch = useAppDispatch();
  const { areas } = useAppSelector((state) => state.areas);
  const pageSize = 3;
  const [activePage, setActivePage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);

  const getEvaluateAreas = async () => {
    try {
      if (archived) {
        dispatch(
          GetArchivedPagedEvaluateAreaDisplayInfosByClientAndUser({
            pageNumber: activePage,
            pageSize: pageSize,
          } as PagedRequest)
        );
      } else {
        dispatch(
          GetPagedEvaluateAreaDisplayInfosByClientAndUser({
            pageNumber: activePage,
            pageSize: pageSize,
          } as PagedRequest)
        );
      }

      setPageCount(Math.ceil(1 / pageSize));

      const extraItem: AreaDisplayInfo = {
        id: "demo",
        title: "Inspection Framework",
        dueDate: new Date(),
        status: "Incomplete",
        participantCount: 0,
        groups: [],
        roles: ["O"],
        organisations: [],
        departments: [],
        percentage: 0,
        legacy: false,
        archived: false,
      };
    } catch (error) {
      console.error(error);
      toast.error(
        `There was a problem retrieving Evalaute areas. Error: ${error}`
      );
    }
  };

  useEffect(() => {
    getEvaluateAreas();
  }, [activePage]);

  const pageChanged = (page: number) => {
    setActivePage(page);
  };

  if (areas) {
    return (
      <div className={styles.outerContainer}>
        {areas.length === 0 ? (
          <p>There are no items to display.</p>
        ) : (
          <>
            <CardCollection archived={archived} areas={areas} template={false} />
            <Paging
              activePage={activePage}
              pageCount={pageCount}
              pageChanged={pageChanged}
            />
          </>
        )}
      </div>
    );
  } else {
    return (
      <>
        <Skeleton loading={true}>
          <div></div>
        </Skeleton>
        <Skeleton loading={true}>
          <div></div>
        </Skeleton>
        <Skeleton loading={true}>
          <div></div>
        </Skeleton>
      </>
    );
  }
};

export default PagedCardCollection;
