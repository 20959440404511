import React from 'react';
//CSS
import styles from './Popup.module.css';
import ReactDOM from 'react-dom';
//Components
//Data

type PopupProps = {
    isVisible: boolean;
    onClose: () => void;
    children?: React.ReactNode;
    style?: React.CSSProperties | undefined;
};

const Popup = ({ isVisible, onClose, children, style } : PopupProps) => {
    
    if (!isVisible) return null;

    return ReactDOM.createPortal(
        <div className={styles.container} onClick={onClose}>
          <div style={style}
            className={styles.popup}
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside the box
          >
            {children}
          </div>
        </div>,
        document.getElementById("popup")!
      );
};

export default Popup;