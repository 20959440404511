import React, { useState, useEffect } from 'react';  
import { Link, Route, Routes, useNavigate, useParams } from 'react-router-dom';  
import layout from '../../../../Modules/layout.module.css';  
import styles from './DefineFields.module.css';  
import { CompleteQuestionnaireTemplateById, GetSectionsByTemplateId } from '../../../../API/TemplateBank/TemplateBank';  
import QuestionsView from './QuestionsView/QuestionsView';  
import SectionsDrawer from '../../../../Components/SectionsDrawer/SectionsDrawer';  
import { DrawerProvider } from '../../../../Components/SectionsDrawer/DrawerContext';  
import { IDrawerItem, IDrawerSection } from '../../../../Types/Drawer/ISectionsDrawerItem';  
import Button from '../../../../Components/Utils/Button/Button';  
  
type DefineQuestionsProps = object;  
  
const DefineQuestions = ({}: DefineQuestionsProps) => {  

  const { templateId, sectionId, criteriaId } = useParams();  

  const navigate = useNavigate();  
  const [sections, setSections] = useState<IDrawerSection[]>([]);  
  
  useEffect(() => {  
    const getSections = async () => {  
      if (templateId) {  
        const response = await GetSectionsByTemplateId(templateId);  
        const drawerSections: IDrawerSection[] = response.map((section) => ({  
          id: section.id,  
          title: section.title,  
          status: section.completionStatus,  
          items: section.criteria.map(item => ({  
            id: item.id,  
            title: item.title,  
            url: `/${section.id}/${item.id}`  
          }))  
        }));  
        setSections(drawerSections);  
      }  
    };  
    getSections();  
  }, [templateId]);  
  
  useEffect(() => {  
    if (sections.length > 0 && sections[0].items.length > 0) {  
        navigate(`/portal/template-editor/${templateId}/define-questions/${sections[0].id}/${sections[0].items[0].id}`);  
    }  
  }, [sections]);  

  const isLastItem = () => {  
      const currentSectionIndex = sections.findIndex(section => section.id === sectionId);  
      if (currentSectionIndex === -1) return false;  
    
      const currentSection = sections[currentSectionIndex];  
      const currentCriteriaIndex = currentSection.items.findIndex(item => item.id === criteriaId);  
      if (currentCriteriaIndex === -1) return false;  
    
      const isLastCriteriaInSection = currentCriteriaIndex === currentSection.items.length - 1;  
      const isLastSection = currentSectionIndex === sections.length - 1;  
    
      return isLastCriteriaInSection && isLastSection;  
  };  
    
  const getNextItemTitle = () => {  

    if (isLastItem()) {  
        return 'Complete Template';  
    }  

      const currentSectionIndex = sections.findIndex(section => section.id === sectionId);  
      if (currentSectionIndex === -1) return '';  
    
      const currentSection = sections[currentSectionIndex];  
      const currentCriteriaIndex = currentSection.items.findIndex(item => item.id === criteriaId);  
      if (currentCriteriaIndex === -1) return '';  
    
      const nextCriteriaIndex = currentCriteriaIndex + 1;  
      if (nextCriteriaIndex < currentSection.items.length) {  
          return currentSection.items[nextCriteriaIndex].title;  
      } else {  
          const nextSectionIndex = currentSectionIndex + 1;  
          if (nextSectionIndex < sections.length) {  
              const nextSection = sections[nextSectionIndex];  
              if (nextSection.items.length > 0) {  
                  return nextSection.items[0].title;  
              }  
          }  
      }  
      return '';  
  };  

  const completeTemplate = async () => {
    if(templateId)
      await CompleteQuestionnaireTemplateById(templateId)
    navigate('/portal');
  }
    
  const nextHandler = () => {  
      if (isLastItem()) {  
          completeTemplate();
          return;  
      }  
    
      const nextTitle = getNextItemTitle();   
    
      const currentSectionIndex = sections.findIndex(section => section.id === sectionId);  
      if (currentSectionIndex === -1) return;  
    
      const currentSection = sections[currentSectionIndex];  
      const currentCriteriaIndex = currentSection.items.findIndex(item => item.id === criteriaId);  
      if (currentCriteriaIndex === -1) return;  
    
      const nextCriteriaIndex = currentCriteriaIndex + 1;  
      if (nextCriteriaIndex < currentSection.items.length) {  
          const nextCriteria = currentSection.items[nextCriteriaIndex];  
          navigate(`/portal/template-editor/${templateId}/define-questions/${currentSection.id}/${nextCriteria.id}`);  
      } else {  
          const nextSectionIndex = currentSectionIndex + 1;  
          if (nextSectionIndex < sections.length) {  
              const nextSection = sections[nextSectionIndex];  
              if (nextSection.items.length > 0) {  
                  const nextCriteria = nextSection.items[0];  
                  navigate(`/portal/template-editor/${templateId}/define-questions/${nextSection.id}/${nextCriteria.id}`); 
              }  
          }  
      }  
  };  
  
  return (  
    <div className={layout.row1}>  
      <div className={layout.column2}>  
        <Routes>  
          <Route path={`/:sectionId/:criteriaId`} element={<QuestionsView />} />  
        </Routes>  
        <div className={`${layout.column1} ${layout.container}`}>  
            <div className={layout.rowReverse}>{`Next: ${getNextItemTitle()}`}</div>
          <div className={`${layout.rowSpan}`}>  
            <Link to={`/portal/template-editor/${templateId}/define-structure`}>  
              <Button>Back: Define Structure</Button>  
            </Link>  
            <Button clickHandler={nextHandler}>{isLastItem()? `Complete Template` : `Next Criteria`}</Button>  
          </div>  
        </div>  
      </div>  
      <div className={`${layout.column}`} style={{maxWidth: "300px"}}>
        <DrawerProvider>  
          <SectionsDrawer sections={sections} basePath={`/portal/template-editor/${templateId}/define-questions`} initialActiveItem={criteriaId}/>  
        </DrawerProvider>  
      </div>
    </div>  
  );  
};  
  
export default DefineQuestions;  