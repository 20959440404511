import React from 'react'
import { AreaDisplayInfo } from '../../../Types/AreaDisplayInfo/AreaDisplayInfo'
import Card from '../../Evaluate/Card/Card'
import style from './CardCollection.module.css'
import { useAppSelector } from '../../../Redux/Store/Store'

type CardCollectionProps = {
    archived: boolean,
    areas: AreaDisplayInfo[],
    template: boolean
}

const CardCollection = ({ archived, areas, template } : CardCollectionProps) => {



  return (
    <div className={style.cardsContainer}>
        {areas.map((item) => {
            const url = template? `/portal/template-editor/${item.id}/define-fields` : `/evaluate/${item.id}`;
            return <Card key={item.id} url={url} areaDisplayInfo={item} archived={archived} template={template}/>
        })}
    </div>
  )
}

export default CardCollection