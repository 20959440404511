import React, { useEffect } from 'react';  
import { Link } from 'react-router-dom';  
import styles from './SectionsDrawerItem.module.css';  
import SectionsDrawerItemCriteria from './SectionsDrawerItemCriteria';  
import CompletionStatus from '../Utils/CompletionStatus/CompletionStatus';  
import { IDrawerItem, IDrawerSection } from '../../Types/Drawer/ISectionsDrawerItem';  
import { useDrawer } from './DrawerContext';
  
type SectionsDrawerItemProps = {  
  section: IDrawerSection;  
  active: boolean;  
  basePath: string;  
  initialActiveItem: string;  
  onClickHandler?: (item: IDrawerItem) => void;  
};  
  
const SectionsDrawerItem: React.FC<SectionsDrawerItemProps> = ({ section, active, basePath, initialActiveItem, onClickHandler }) => {  
  const { activeItem, setActiveItem } = useDrawer();  
  
  useEffect(() => {  
    const item = section.items.find(item => item.id === initialActiveItem);  
    if (item) {  
      setActiveItem(item);  
      if (onClickHandler) onClickHandler(item);  
    }  
  }, [section, initialActiveItem, setActiveItem, onClickHandler]);  
  
  return (  
    <div className={`${styles.container} ${active && styles.active}`}>  
      <div className={styles.titleContainer}>  
        <h3>{section.title}</h3>  
        <CompletionStatus status={section.status ?? 'Incomplete'} />  
      </div>  
      <div className={styles.criteriaItems}>  
        {active && (  
          <div className={styles.criteriaItems}>  
            {section.items.map(item => (  
              <Link  
                to={basePath + item.url}  
                key={item.title}  
                onClick={item === activeItem ? undefined : () => setActiveItem(item)}  
              >  
                <p  
                  className={styles.item}  
                  style={{ fontWeight: item === activeItem ? '700' : '500', padding: '7px 0' }}  
                >  
                  {item.title}  
                </p>  
              </Link>  
            ))}  
          </div>  
        )}  
      </div>  
    </div>  
  );  
};  
  
export default SectionsDrawerItem;  