import React, { useState } from "react";
//CSS
import layout from "../../../../Modules/layout.module.css";
import styles from "./CreateImprovementQuestionSectionOption.module.css";
import Checkbox from "../../../Utils/Checkbox/Checkbox";
import { useSelector } from "react-redux";
import { RootState } from "../../../../Redux/Store/Store";
import Popup from "../../../Utils/Popup/Popup";
import { IDynamicField } from "../../../../Types/Products/IDynamicField";
//Components
//Data

type CreateImprovementQuestionSectionOptionProps = {
  title: string;
  id: string;
  onQuestionSelected: (questionId: string, isSelected: boolean) => void;
  areasForImprovement: IDynamicField | null;
};

const CreateImprovementQuestionSectionOption = ({
  title,
  id,
  onQuestionSelected,
  areasForImprovement,
}: CreateImprovementQuestionSectionOptionProps) => {
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [showAreasForImprovement, setShowAreasForImprovement] =
    useState<boolean>(false);

  const checkboxClickHandler = () => {
    const newSelectionState = !isSelected;
    setIsSelected(newSelectionState);
    onQuestionSelected(id!, newSelectionState); // Pass the question ID and new selection state
  };

  return (
    <div
      className={`${styles.container} ${layout.row1} ${
        isSelected && styles.checked
      }`}
    >
      <div className={`${styles.left} ${layout.row1} ${layout.centered}`}>
        <Checkbox clickHandler={checkboxClickHandler} />
        <p>{title ? title : "Option title here"}</p>
      </div>
      {areasForImprovement && (
        <div
          className={`${styles.right} ${layout.row} ${layout.centered} ${layout.justifyFlexEnd}`}
          onClick={() => setShowAreasForImprovement(!showAreasForImprovement)}
        >
          View Areas for Improvement
          <Popup
            isVisible={showAreasForImprovement}
            onClose={() => setShowAreasForImprovement(!showAreasForImprovement)}
          >
            <p>{areasForImprovement.content}</p>
          </Popup>
        </div>
      )}
    </div>
  );
};

export default CreateImprovementQuestionSectionOption;
