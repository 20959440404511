import React, { useEffect, useState } from 'react'
//CSS
import styles from '../Routes.module.css';
import layout from '../../Modules/layout.module.css'
import CurateDashboardBanner from '../../Components/CurateBanner/CurateDashboardBanner';
import { IProductQuestionnaire } from '../../Types/Products/IProductQuestionnaire';
import { AreaDisplayInfo } from '../../Types/AreaDisplayInfo/AreaDisplayInfo';
import CardCollection from '../../Components/Utils/LandingPages/CardCollection';
import { useNavigate } from 'react-router-dom';
import Button from '../../Components/Utils/Button/Button';
import { GetPagedQuestionnaireTemplates, CreateQuestionnaireTemplateByTitle } from '../../API/TemplateBank/TemplateBank';
import CreationBar from '../../Components/Utils/Actions/CreationBar/CreationBar';

const Portal = () => {

  const tempTemplates: AreaDisplayInfo[] = [
    {
      id: 'asdfazxvzxvzxc',
      title: 'This is a template test',
      dueDate: new Date(),
      status: 'Complete',
      participantCount: 0,
      groups: [],
      roles: [],
      organisations: [],
      departments: [],
      percentage: 0,
      legacy: false,
      archived: false
    }
  ]

  const navigate = useNavigate();

  const [templates, setTemplates] = useState<AreaDisplayInfo[]>([])

  useEffect(() => {
    const getTemplates = async () => {
      var response = await GetPagedQuestionnaireTemplates(1, 200);
      setTemplates(response.items);
    }
    getTemplates();
  }, [])
  
  const createTemplate = async (title: string) => {
    const templateId = await CreateQuestionnaireTemplateByTitle(title);
    navigate(`/portal/template-editor/${templateId}/define-fields`)
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>Template Editor</h1>
      </div>
      <div className={styles.body}>

        <div className={layout.column2}>
          <div className={layout.rowSpan}>
            <p>Use the assistance of AI to create and edit questionnaires for Self Assessment templates.</p>
          </div>
           

          { templates.length > 0? <CardCollection template={true} archived={false} areas={templates}></CardCollection> : <></> }

          <CreationBar onCreateHandler={createTemplate} placeholder={'Enter title for new template'} noTextMessage={'The Title field is required'}></CreationBar>
          
        </div>
      </div>
    </div>
  )
}

export default Portal