import React from 'react';
//CSS
import styles from './ActionBar.module.css';
import layout from '../../../../Modules/layout.module.css'
import effects from '../../../../Modules/effects.module.css'
import { IActionFeature } from '../../../../Types/Actions/IActionFeature';
//Components
//Data

type ActionBarProps = {
    enabledFeatures: IActionFeature[],
    onDeleteHandler?: () => void,
    onArchiveHandler?: () => void,
    onExportHandler?: () => void,
    selectedCount: number
};

const ActionBar = ({ enabledFeatures, onDeleteHandler, onArchiveHandler, onExportHandler, selectedCount } : ActionBarProps) => {
    const isDisabled = selectedCount === 0;  
  
    return (  
      <div className={`${layout.rowSpan20} ${styles.container} ${effects.shadow}`}>  
        <div className={layout.row}>  
          <button  
            className={`${styles.actionButton} ${styles.toHover} ${isDisabled ? effects.disabled : ''}`}  
            onClick={onDeleteHandler}  
            title='Select items to delete'
          >  
            <img src='/img/trash.svg' alt="Delete" />  
            <p>Delete</p>  
          </button>  
          <button  
            className={`${styles.actionButton} ${isDisabled ? effects.disabled : ''}`}  
            onClick={onArchiveHandler}  title='Select items to archive'
          >  
            <img src='/img/archive.svg' alt="Archive" />  
            <p>Archive</p>  
          </button>  
          <button  
            className={`${styles.actionButton} ${isDisabled ? effects.disabled : ''}`}  
            onClick={onExportHandler} title='Select items to export'
          >  
            <img src='/img/document-upload.svg' alt="Export" />  
            <p>Export</p>  
          </button>  
        </div>  
        <div className={styles.right}>  
          <p>Items Selected</p>  
          <h2 className={`${styles.numberSelected} ${layout.blue}`}>{selectedCount}</h2>  
        </div>  
      </div>  
    );  
  };  
    
  export default ActionBar;  