//CSS
import styles from './Navbar.module.css';
//Components
import NavbarListItem from './NavbarItem/NavbarItem';
//Data
import { NavbarData } from '../../Data/NavbarData';
import { useState } from 'react';
import { NavbarItem } from '../../Types/NavbarItem';

const Navbar = () => {

  let topNavItems : NavbarItem[] = [];
  let bottomNavItems : NavbarItem[] = [];

  const items = NavbarData;

  const [navItemStatus, setNavItemStatus] = useState([
    ...NavbarData
  ]);

  const isRunningStandalone = (): boolean => {
    return window.matchMedia('(display-mode: standalone)').matches;
  };

  const removeActiveStatus = () :void => {
        const state: NavbarItem[] = [ ...navItemStatus ];
        state.forEach(element => {
          element.active = false;
        });
  }

  const addActiveStatus = (id:number) => {

        // Destructure current state array
        const state: NavbarItem[] = [ ...navItemStatus ];
        state.forEach(element => {
          element.active = false;
        });

        // Find object from id
        let index = state.findIndex(x => x.id === id)

        // Set clicked link to active
        state[index].active = true;

        // Set state with updated active status
        setNavItemStatus(() => {
          return [
            ...state
          ]
        });

  }

  const sortNavItems = () => {
    for(let i = 0; i < items.length; i++) {
      if(items[i].id > 7) {
        bottomNavItems.push(items[i])
      } else {
        topNavItems.push(items[i]);
      }
    }
  }

  const showLimitedNavItems = (): void => {
      items.splice(4)
  }

  if(!isRunningStandalone()) {
    sortNavItems();
  } else {
    showLimitedNavItems();
  }

removeActiveStatus();

  return (
    <nav className={styles.container}>
        <div className={styles.header}>
            <img src='/img/logo.png' />
        </div>
          {!isRunningStandalone() ? (
          <div className={styles.body}>
              <ul className={styles.topNav}>
                  {items.map((item)=>{
                      return <NavbarListItem item={item} key={item.id} statusHandler={addActiveStatus}/>
                    })}
              </ul>
          </div>
          ) : (
          <div className={styles.body}>
              {items.map((item)=>{
                return <NavbarListItem item={item} key={item.id} statusHandler={addActiveStatus}/>
              })}
          </div>
          )
}
    </nav>
  )
}

export default Navbar