import React, { useEffect, useState } from 'react';   
import styles from './DefineStructure.module.css';    
import layout from '../../../../Modules/layout.module.css';    
import effects from '../../../../Modules/effects.module.css';    
import { Link, useParams } from 'react-router-dom';    
import ActionBar from '../../../../Components/Utils/Actions/ActionBar/ActionBar';    
import CreationBar from '../../../../Components/Utils/Actions/CreationBar/CreationBar';    
import Button from '../../../../Components/Utils/Button/Button';    
import TitledField from '../../../../Components/Utils/TitledField/TitledField';    
import { IDynamicField, FieldType, SpecialField } from '../../../../Types/Products/IDynamicField';    
import Checkbox from '../../../../Components/Utils/Checkbox/Checkbox';    
import { IQuestionnaireSection } from '../../../../Types/Products/IQuestionnaireSection';    
import { ICriteriaItem } from '../../../../Types/Products/ICriteriaItemData';  
import { CreateCriteriaByQuestionnaireTemplateIdAndSectionIdAndTitle, CreateSectionByQuestionnaireTemplateId, DeleteSectionsAndCriteriaByTemplateId, GetSectionsByTemplateId } from '../../../../API/TemplateBank/TemplateBank';  
import { getuid } from 'process';    
  
type DefineStructureProps = object;      
  
const DefineStructure = ({}: DefineStructureProps) => {      
  const { templateId } = useParams();        
  /*const temp: IQuestionnaireSection[] = [        
    {          
      id: '1',          
      title: 'Behaviour and attitudes',          
      description: '',          
      active: false,          
      criteria: [            
        {              
          id: '11',              
          title: 'BA1 Behaviour and attitudes',              
          description: '',              
          active: false,              
          questions: [],              
          grade: 'Red',              
          tags: [],              
          fields: []            
        },            
        {              
          id: '12',              
          title: 'BA2 Attendance',              
          description: '',              
          active: false,              
          questions: [],              
          grade: 'Red',              
          tags: [],              
          fields: []            
        }          
      ],          
      completionStatus: 'Complete'        
    },        
    {          
      id: '2',          
      title: 'Behaviour and attitudes',          
      description: '',          
      active: false,          
      criteria: [            
        {              
          id: '21',              
          title: 'BA1 Behaviour and attitudes',              
          description: '',              
          active: false,              
          questions: [],              
          grade: 'Red',              
          tags: [],              
          fields: []            
        },            
        {              
          id: '22',              
          title: 'BA2 Attendance',              
          description: '',              
          active: false,              
          questions: [],              
          grade: 'Red',              
          tags: [],              
          fields: []            
        }          
      ],          
      completionStatus: 'Complete'        
    }      
  ];*/
    
  const [selectedSections, setSelectedSections] = useState<string[]>([]);      
  const [selectedItems, setSelectedItems] = useState<string[]>([]);      
  const [sections, setQuestionnaireSections] = useState<IQuestionnaireSection[]>([]);      
  const [newCriteriaTitles, setNewCriteriaTitles] = useState<{ [key: string]: string }>({}); // State to manage new criteria titles for each section  
  
  useEffect(() => {    
    const getSections = async () => {        
      if(templateId){          
        const response = await GetSectionsByTemplateId(templateId);          
        setQuestionnaireSections(response);        
      }      
    };      
    getSections();  
  }, [templateId]);        
    
  const handleSectionSelection = (sectionId: string, selected: boolean) => {  
    setSelectedSections((prevSelectedSections) => {  
      let newSelectedSections = [...prevSelectedSections];  
      const section = sections.find((section) => section.id === sectionId);  
  
      if (section) {  
        if (selected) {  
          if (!newSelectedSections.includes(sectionId)) {  
            newSelectedSections.push(sectionId);  
          }  
          // Add all criteria in the section to selectedItems  
          setSelectedItems((prevSelectedItems) => {  
            let newSelectedItems = [...prevSelectedItems];  
            section.criteria.forEach((criterion) => {  
              if (!newSelectedItems.includes(criterion.id)) {  
                newSelectedItems.push(criterion.id);  
              }  
            });  
            return newSelectedItems;  
          });  
        } else {  
          newSelectedSections = newSelectedSections.filter((id) => id !== sectionId);  
          // Remove all criteria in the section from selectedItems  
          setSelectedItems((prevSelectedItems) => {  
            return prevSelectedItems.filter((id) => !section.criteria.some((criterion) => criterion.id === id));  
          });  
        }  
      }  
  
      return newSelectedSections;  
    });  
  };  
  
  const handleItemSelection = (itemId: string, selected: boolean) => {        
    setSelectedItems((prevSelectedItems) => {          
      let newSelectedItems = [...prevSelectedItems];            
      if (selected) {            
        if (!newSelectedItems.includes(itemId)) {              
          newSelectedItems.push(itemId);            
        }          
      } else {            
        newSelectedItems = newSelectedItems.filter((id) => id !== itemId);          
      }            
      return newSelectedItems;        
    });      
  };          
  
  const handleTitleChange = (itemId: string, newTitle: string) => {        
    setQuestionnaireSections((prevFields) =>          
      prevFields.map((field) =>            
        field.id === itemId ? { ...field, title: newTitle } : field          
      )        
    );      
  };    
    
 
  const deleteItems = async () => {  
    if (templateId) {  
      try {  
        await DeleteSectionsAndCriteriaByTemplateId(templateId, [...selectedSections, ...selectedItems]);  
        setQuestionnaireSections((prevFields) =>  
          prevFields  
            .filter((field) => !selectedSections.includes(field.id)) // Remove entire sections that are selected  
            .map((field) => ({  
              ...field,  
              criteria: field.criteria.filter((item) => !selectedItems.includes(item.id)), // Remove selected criteria  
            }))  
        );  
        setSelectedSections([]);  
        setSelectedItems([]);  
      } catch (error) {  
        console.error('Error deleting items:', error);  
      }  
    }  
  };  
    
  const createSection = async (title: string) => {    
    if(templateId){        
      const newSection = await CreateSectionByQuestionnaireTemplateId(templateId, title);        
      setQuestionnaireSections([...sections, newSection]);        
    }    
  };    
    
  const createCriteria = async (sectionId: string, title: string) => {    
    if(templateId){        
      const newCriterion = await CreateCriteriaByQuestionnaireTemplateIdAndSectionIdAndTitle(templateId, sectionId, title);  
      setQuestionnaireSections((prevSections) =>            
        prevSections.map(section => {              
          if (section.id === sectionId) {                
            return {                  
              ...section,                  
              criteria: [...section.criteria, newCriterion]                
            };              
          }              
          return section;            
        })          
      );        
    }    
  };        
    
  const handleCriteriaInputChange = (sectionId: string, newTitle: string) => {  
    setNewCriteriaTitles((prevTitles) => ({  
      ...prevTitles,  
      [sectionId]: newTitle  
    }));  
  };  
  
  const handleCriteriaInputBlur = (sectionId: string) => {  
    if (newCriteriaTitles[sectionId]) {  
      createCriteria(sectionId, newCriteriaTitles[sectionId]);  
      setNewCriteriaTitles((prevTitles) => ({  
        ...prevTitles,  
        [sectionId]: ''  
      }));  
    }  
  };  
  
  return (        
    <div className={layout.container}>          
      <div className={layout.column1}>            
        <ActionBar enabledFeatures={['delete']} selectedCount={[...selectedItems, ...selectedSections].length} onDeleteHandler={deleteItems} />            
        {sections.map(({ id, title, description, criteria }) => {              
          return (                
            <TitledField                  
              key={id}                  
              title={title}                  
              text={description}                  
              onSelectedHandler={(selected: boolean) => handleSectionSelection(id, selected)}                  
              titleChanged={(newTitle: string) => handleTitleChange(id, newTitle)}                
            >                  
              <div className={`${layout.column} `}>                    
                {criteria.map(({ id: criterionId, title: criterionTitle, description: criterionDescription }) => {                      
                  return (                        
                    <div className={`${layout.row} ${layout.centered} ${layout.paddingLeft10}`} key={criterionId}>                          
                      <Checkbox                            
                        clickHandler={(selected: boolean) => handleItemSelection(criterionId, selected)}                            
                        value={selectedItems.includes(criterionId)}                          
                      />                          
                      <h3 className={layout.padding15}>{criterionTitle}</h3>                        
                    </div>                      
                  );                    
                })}                    
                <input   
                className={styles.inputCreate}
                  placeholder='+ Add Criteria'   
                  value={newCriteriaTitles[id] || ''} // Bind input to state for the specific section  
                  onChange={(e) => handleCriteriaInputChange(id, e.target.value)} // Update state on input change for the specific section  
                  onBlur={() => handleCriteriaInputBlur(id)} // Handle blur event for the specific section  
                  onKeyDown={(e) => {  
                    if (e.key === 'Enter') {  
                      handleCriteriaInputBlur(id); // Handle enter key event  
                      e.preventDefault(); // Prevent default behavior to avoid form submission if inside a form  
                    }  
                  }}  
                />  
              </div>                
            </TitledField>              
          );            
        })}            
        <p></p>            
        <CreationBar              
          onCreateHandler={createSection}              
          placeholder={'Enter title of new section...'}              
          noTextMessage={'*'}            
        />            
        <p></p>            
        <div className={`${layout.column1} ${layout.container}`}>       
          <div className={layout.rowReverse}><p>Next please create questions for each criteria</p></div>          
          <div className={`${layout.rowSpan}`}>   
            <Link to={`/portal/template-editor/${templateId}/define-fields`}>
              <Button>Back: Define fields</Button>
            </Link>
            {sections.length > 0 && sections[0].criteria.length > 0 ? 
              <Link to={`/portal/template-editor/${templateId}/define-questions`}>  
                <Button>Next</Button>  
              </Link>  
           : 
              <Button>Next</Button>  
            }     
          </div>            
        </div>          
      </div>        
    </div>      
  );    
};      
  
export default DefineStructure;  