import React, { useEffect, useState } from 'react'
//CSS
import styles from './Card.module.css';
//Components
import Button from '../../Utils/Button/Button';
import ProgressBar from '../../Utils/ProgressBar/ProgressBar';
import { AreaDisplayInfo } from '../../../Types/AreaDisplayInfo/AreaDisplayInfo';
import { Link } from 'react-router-dom';
import { SetArchivedEvaluateAreaRequest } from '../../../Types/Evaluate/SetArchivedEvaluateAreaRequest';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../../Redux/Store/Store';
import { SetArchivedEvaluateAreaById } from '../../../Redux/Store/Slices/area';
import { GetGradeColour } from '../../../Data/ColourData';

type CardProps = {
    areaDisplayInfo: AreaDisplayInfo,
    url: string,
    editHandler?: () => void,
    archived: boolean,
    template: boolean
}


const Card = ({ areaDisplayInfo: {
    id,
    title,
    dueDate,
    status,
    participantCount,
    groups,
    roles,
    organisations,
    departments,
    percentage,
}, url, archived, template }: CardProps) => {

    const [progress, setProgress] = useState(0)

    const dispatch = useAppDispatch();

    useEffect(() => {
        setTimeout(() => {
            setProgress(percentage)
        }, 200)
    }, [])

    const archiveAreaByIdHandler = async () => {
        try {

            let body : SetArchivedEvaluateAreaRequest = {
                areaId: id,
                archived: false
            };
            
            if (archived) {
                body.archived = false;
                dispatch(SetArchivedEvaluateAreaById(body));
                toast.success(`${title} area has been successfully unarchived.`)
            } else {
                body.archived = true;
                dispatch(SetArchivedEvaluateAreaById(body));
                toast.success(`${title} area has been successfully archived.`)
            }

        } catch (error) {
            console.error(error);
            toast.error(`Evaluate area archive status failed to set. Error: ${error}`)
        }

    }

    return (
        <div className={styles.container}>
            <div className={styles.status}>
                <div className={styles.statusLeft}>
                    <ProgressBar percentage={progress} />
                    <p>{`${percentage}%`}</p>
                </div>
                <div className={styles.statusRight}>
                    {percentage === 100 ? (
                        <Button size="Small" colour="#52BFA2" textColour="#FFF">Complete</Button>
                    ) : percentage === 0 ? (
                        <Button size="Small" colour="#E25A74" textColour="#FFF">Incomplete</Button>
                    ) : <Button size="Small" colour={GetGradeColour("Amber")} textColour="#FFF">In Progress</Button>}
                </div>
            </div>
            <div className={styles.title}>
                <h2 style={template? {paddingBottom: "15px"} : {}}>{title}</h2>
            </div>
            {template? <></> :
                <div className={styles.info}>
                    <div className={styles.infoLeft}>
                        <p>Due: Today</p>
                        <p className={styles.participants}>{participantCount} Participants</p>
                        <p>Group: {groups?.join(", ")}</p>
                    </div>
                    <div className={styles.infoRight}>
                        <p>Role: {roles?.join(", ")}</p>
                        <p>Organisation: {organisations?.join(", ")}</p>
                        <p>Department: {departments?.join(", ")}</p>
                    </div>
                </div>
            }
            <div className={styles.footer}>
                <div className={styles.footerLeft}>
                    <Button size="Small" colour="#FFF" textColour="#000" border="rgba(0,0,0,0.2)" clickHandler={archiveAreaByIdHandler}>
                        {archived ? "Unarchive" : "Archive"}
                    </Button>
                    <Button size="Small" colour="#FFF" textColour="#000" border="rgba(0,0,0,0.2)">Export</Button>
                </div>
                <div className={styles.footerRight}>
                
                    {template? <></> :
                    <Button size="Small" colour="#FFF" textColour="#000" border="rgba(0,0,0,0.2)">
                        {percentage < 100 ? "Complete Framework" : "Edit Framework"}
                    </Button>
                    }

                    <Link to={url}>
                        <Button size="Small" colour="#FFF" textColour="#000" border="rgba(0,0,0,0.2)">
                            {percentage < 100 ? `Complete ${template? "Template" : "Summary"}` : `Edit ${template? "Template" : "Summary"}`}
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Card