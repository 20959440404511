import { AxiosResponse } from "axios";
import { axiosInstance } from "../../Authentication/AxiosInterceptor";
import { AreaDisplayInfo } from "../../Types/AreaDisplayInfo/AreaDisplayInfo";
import { PagedResponse } from "../../Types/Paging/PagedResponse";
import { IProductQuestionnaire } from "../../Types/Products/IProductQuestionnaire";
import { PagedRequest } from "../../Types/Paging/PagedRequest";
import { IDynamicFieldPrototype, IDynamicField } from "../../Types/Products/IDynamicField";
import { IQuestionnaireSection } from "../../Types/Products/IQuestionnaireSection";
import { ICriteriaBankItem } from "../../Types/TemplateBank/ICriteriaBankItem";
import { ICriteriaItem } from "../../Types/Products/ICriteriaItemData";
import { IQuestionnaireQuestion } from "../../Types/Products/IQuestionnaireQuestion";
import { CreateQuestionRequest, DeleteQuestionsRequest } from "../../Types/TemplateBank/CreateQuestionRequest";


export async function ImportQuestionnaireTemplate(templateId: string): Promise<IProductQuestionnaire>{

    try {
        const response: AxiosResponse<IProductQuestionnaire> = await axiosInstance.get(`ImportQuestionnaireTemplate/${templateId}`);
    
        return response.data;
       
      } catch (error) {
        console.log(error);
        throw error;
      }
}

export async function GetPagedQuestionnaireTemplates(pageNumber: number, pageSize: number): Promise<PagedResponse<AreaDisplayInfo>>{

  try {
      const response: AxiosResponse<PagedResponse<AreaDisplayInfo>> = await axiosInstance.put(`GetPagedQuestionnaireTemplates`, {
        pageNumber: pageNumber,
        pageSize: pageSize
      } as PagedRequest);
  
      return response.data;
     
    } catch (error) {
      console.log(error);
      throw error;
    }
}

export async function CreateFieldPrototype(title: string, templateId: string): Promise<IDynamicFieldPrototype>{

  try {
    const response: AxiosResponse<IDynamicFieldPrototype> = await axiosInstance.post(`CreateFieldPrototype`, {
      templateId: templateId,
      fieldTitle: title
    });
    return response.data;
  
    } catch (error) {
      console.log(error);
      throw error;
    }
}

export async function DeleteFieldPrototypesByIdList(fieldIds: string[]){

  try {
    const response: AxiosResponse = await axiosInstance.post(`DeleteFieldPrototypesByIdList`, fieldIds);
  
    } catch (error) {
      console.log(error);
      throw error;
    }
}

export async function GetFieldPrototypesByTemplateId(templateId: string): Promise<IDynamicField[]>{

  try {
    var response: AxiosResponse<IDynamicField[]> = await axiosInstance.get(`GetFieldPrototypesByTemplateId/${templateId}`);
       return response.data;
    
    } catch (error) {
      console.log(error);
      throw error;
    }
}

export async function CreateQuestionnaireTemplateByTitle(title: string): Promise<string>{

  try {
    var response: AxiosResponse<string> = await axiosInstance.put(`CreateQuestionnaireTemplateByTitle/${title}`);
       return response.data;
    
    } catch (error) {
      console.log(error);
      throw error;
    }
}

export async function CreateSectionByQuestionnaireTemplateId(templateId: string, title: string): Promise<IQuestionnaireSection>{

  try {
       var response: AxiosResponse<IQuestionnaireSection> = await axiosInstance.put(`CreateSectionByQuestionnaireTemplateId/${templateId}/${title}`);
       return response.data;
    
    } catch (error) {
      console.log(error);
      throw error;
    }
}

export async function CreateCriteriaByQuestionnaireTemplateIdAndSectionIdAndTitle(templateId: string, sectionId: string, title: string): Promise<ICriteriaItem>{

  try {
       var response: AxiosResponse<ICriteriaItem> = await axiosInstance.put(`CreateCriteriaByQuestionnaireTemplateIdAndSectionIdAndTitle/${templateId}/${sectionId}/${title}`);
       return response.data;
    
    } catch (error) {
      console.log(error);
      throw error;
    }
}

export async function GetSectionsByTemplateId(templateId: string): Promise<IQuestionnaireSection[]>{

  try {
    var response: AxiosResponse<IQuestionnaireSection[]> = await axiosInstance.get(`GetSectionsByTemplateId/${templateId}`);
       return response.data;
    
    } catch (error) {
      console.log(error);
      throw error;
    }
}

export async function DeleteSectionsAndCriteriaByTemplateId(templateId: string, itemIds: string[]){

  try {
    const response: AxiosResponse = await axiosInstance.post(`DeleteSectionsAndCriteriaByTemplateId/${templateId}`, itemIds);
  
    } catch (error) {
      console.log(error);
      throw error;
    }
}

export async function GetQuestionsByTemplateIdAndCriteriaId(templateId: string, criteriaId: string): Promise<IQuestionnaireQuestion[]>{

  try {
    var response: AxiosResponse<IQuestionnaireQuestion[]> = await axiosInstance.get(`GetQuestionsByTemplateIdAndCriteriaId/${templateId}/${criteriaId}`);
       return response.data;
    
    } catch (error) {
      console.log(error);
      throw error;
    }
}

export async function CreateQuestionInQuestionnaireTemplate(templateId: string, sectionId: string, criteriaId: string, questionText: string): Promise<IQuestionnaireQuestion>{

  try {
    const response: AxiosResponse<IQuestionnaireQuestion> = await axiosInstance.post('CreateQuestionInQuestionnaireTemplate', {  
      templateId: templateId,  
      sectionId: sectionId,  
      criteriaId: criteriaId,  
      questionText: questionText  
  } as CreateQuestionRequest);  
    
  return response.data;  
  
    
    } catch (error) {
      console.log(error);
      throw error;
    }
}

export async function DeleteQuestionsInCriteriaTemplate(templateId: string, sectionId: string, criteriaId: string, questionIds: string[]): Promise<void> {  
  try {  
    const requestBody: DeleteQuestionsRequest = {  
      templateId: templateId,  
      sectionId: sectionId,  
      criteriaId: criteriaId,  
      questionIds: questionIds  
    };  
  
    const response: AxiosResponse = await axiosInstance.post('DeleteQuestionsInCriteriaTemplate', requestBody);  
    console.log('Delete questions response:', response);  
  } catch (error) {  
    console.error('Error deleting questions:', error);  
    throw error;  
  }  
}  

export async function CompleteQuestionnaireTemplateById(templateId: string): Promise<void> {  
  try {  
    const response: AxiosResponse = await axiosInstance.put(`CompleteQuestionnaireTemplateById/${templateId}`);  

  } catch (error) {  
    console.error('Error deleting questions:', error);  
    throw error;  
  }  
}  