import React, { useState, useEffect } from 'react';  
import { Link, useParams } from 'react-router-dom';  
import TitledField from '../../../../Components/Utils/TitledField/TitledField';  
import CreationBar from '../../../../Components/Utils/Actions/CreationBar/CreationBar';  
import ActionBar from '../../../../Components/Utils/Actions/ActionBar/ActionBar';  
import layout from '../../../../Modules/layout.module.css';  
import { FieldType, IDynamicField, IDynamicFieldPrototype, SpecialField } from '../../../../Types/Products/IDynamicField';  
import Button from '../../../../Components/Utils/Button/Button';
import styles from './DefineFields.module.css'
import { GetFieldPrototypesByTemplateId, CreateFieldPrototype, DeleteFieldPrototypesByIdList } from '../../../../API/TemplateBank/TemplateBank';
  
type DefineFieldsProps = object;  
  
const DefineFields = ({}: DefineFieldsProps) => {  
  const { templateId } = useParams();  

  /*const temp: IDynamicFieldPrototype[] = [  
    {
      id: "1",
      templateId: undefined,
      title: "What We Do",
      content: "",
      type: FieldType.Text,
      specialField: SpecialField.None,
      archived: false
    },  
    {
      id: "2",
      templateId: undefined,
      title: "What We Do Well",
      content: "",
      type: FieldType.Text,
      specialField: SpecialField.None,
      archived: false
    },  
    {
      id: "3",
      templateId: undefined,
      title: "Areas For Improvement",
      content: "",
      type: FieldType.Text,
      specialField: SpecialField.AreasForImprovement,
      archived: false
    }  
  ];  */
  
  const [selectedItems, setSelectedItems] = useState<string[]>([]);  
  const [fields, setDynamicFields] = useState<IDynamicField[]>([]);  
  
  useEffect(() => {  

    if(templateId){
      const getFields = async () => {
        const response = await GetFieldPrototypesByTemplateId(templateId);
        setDynamicFields(response);
      }
      getFields();
    }
    
  }, [templateId]);  
  
  const itemSelected = (itemId: string, selected: boolean) => {  
    setSelectedItems((prevState) => {  
      if (selected) {  
        // Add itemId to the list  
        return [...prevState, itemId];  
      } else {  
        // Remove itemId from the list  
        return prevState.filter((id) => id !== itemId);  
      }  
    });  
  };  
  
  const handleTitleChange = (itemId: string, newTitle: string) => {  
    setDynamicFields((prevFields) =>  
      prevFields.map((field) =>  
        field.id === itemId ? { ...field, title: newTitle } : field  
      )  
    );  
  };  
  
  const handleTextChange = (itemId: string, text: string) => {  
    setDynamicFields((prevFields) =>  
      prevFields.map((field) =>  
        field.id === itemId ? { ...field, content: text } : field  
      )  
    );  
  };  
  
  const createItem = async (title: string) => {  
    if(templateId){
      const newField = await CreateFieldPrototype(title, templateId);
      setDynamicFields([...fields, newField]);  
    }
  };  

  const deleteItems = async () => {  

    await DeleteFieldPrototypesByIdList(selectedItems);

    setDynamicFields((prevFields) =>  
      prevFields.filter((field) => !selectedItems.includes(field.id)) 
    );  

    setSelectedItems([]); // Clear the selected items after deletion  
  };  
  
  return (  
    <div className={layout.container}>  
      <div className={layout.column1}>  
        <ActionBar enabledFeatures={['delete']} selectedCount={selectedItems.length} onDeleteHandler={deleteItems}/>  

        {fields.map(({ id, title, content }) => {  
          return (  
            <TitledField  
              key={id}  
              title={title}  
              text={content}  
              onSelectedHandler={(selected: boolean) => itemSelected(id, selected)}  
              titleChanged={(newTitle: string) => handleTitleChange(id, newTitle)}  
              valueChanged={(text: string) => handleTextChange(id, text)}  
            />  
          );  
        })}  
        <p></p>  

        <CreationBar  
        onCreateHandler={createItem}  
        placeholder={'Enter title of new field...'}  
        noTextMessage={'*'}  
        />  

        <p></p>  

        <div className={`${layout.column1} ${layout.container}`}>       
          <div className={layout.rowReverse}><p>Next please define the structure of your questionnaire</p></div>          
          <div className={`${layout.rowSpan}`}>   
            <Link to={`/portal`}>
              <Button>Exit: Portal</Button>
            </Link>
            <Link to={`/portal/template-editor/${templateId}/define-structure`}>
              <Button>Next</Button>
            </Link>            
          </div>            
        </div>          
      </div>  
    </div>  
  );  
};  
  
export default DefineFields;  