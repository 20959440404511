import React, { useEffect, useState } from 'react';
//CSS
import styles from './Checkbox.module.css';
//Components
//Data

type CheckboxProps = {
    clickHandler: (x: boolean) => void,
    color?: string,
    value?: boolean
};

const Checkbox = ({ clickHandler, color, value } : CheckboxProps) => {

const [isChecked, setIsChecked] = useState<boolean>(value ?? false);

useEffect(() => {
  if(value)
  setIsChecked(value)
}, [value])

  return (
    <div
        className={`${styles.container} ${isChecked ? styles.checked : ''}`}
        onClick={() => {clickHandler(!isChecked); setIsChecked(!isChecked); }}
        style={{background: isChecked? color : undefined}}
    >
      <img src='/img/tick.svg' />
    </div>
  );
};

export default Checkbox;