import { configureStore, createSelector } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { QuestionnaireSlice } from "./Slices/questionnaire";
import {AuthSlice} from "./Slices/auth";
import { AreaSlice } from "./Slices/area";

export const store = configureStore({
    reducer:{
        auth: AuthSlice.reducer,
        questionnaire: QuestionnaireSlice.reducer,
        areas: AreaSlice.reducer
    }
})

export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch:()=> typeof store.dispatch=useDispatch;
export const useAppSelector:TypedUseSelectorHook<ReturnType<typeof store.getState>>=useSelector;