import ReactDOM from "react-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ToastPortal = () => {

  const alertsDiv = document.getElementById("alerts");

  return alertsDiv
    ? ReactDOM.createPortal(
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          closeButton={true}
          theme="colored"
        />,
        alertsDiv
      )
    : null;
};

export default ToastPortal;
