import React, { useEffect, useState } from 'react';
//CSS
import styles from './ImportTemplate.module.css';
import layout from '../../../Modules/layout.module.css'
import FloatingLabelSelect from '../../Utils/Form/FloatingLabelSelect/FloatingLabelSelect';
import { IProductQuestionnaire } from '../../../Types/Products/IProductQuestionnaire';
import { GetPagedQuestionnaireTemplates } from '../../../API/TemplateBank/TemplateBank';
import { AreaDisplayInfo } from '../../../Types/AreaDisplayInfo/AreaDisplayInfo';
import { PagedResponse } from '../../../Types/Paging/PagedResponse';
import { FloatingLabelSelectOption } from '../../../Types/Form/FloatingLabelSelectOption';
import Form from '../../Utils/Form/Form';
import Button from '../../Utils/Button/Button';
//Components
//Data

type ImportTemplateProps = {
    importHandler: (templateId: string) => void
};

const ImportTemplate = ({ importHandler } : ImportTemplateProps) => {

    const [options, setOptions] = useState<FloatingLabelSelectOption[]>([])
    const [seletedItem, setSelectedItem] = useState<FloatingLabelSelectOption>()

    useEffect(() => {

        const getTemplates = async () => {
            const response: PagedResponse<AreaDisplayInfo> = await GetPagedQuestionnaireTemplates(1, 100);
            const mappedData = response.items.map(({id, title}) => {
                return { value: id, label: title } as FloatingLabelSelectOption
            });
            setOptions(mappedData);
        };
        getTemplates();

    }, [])

    useEffect(() => {
        if(options.length > 0)
        setSelectedItem({value: options[0].value, label: options[0].label})
    }, [options])

    const handleTemplateSelected = (value: string) => {
        setSelectedItem(options.find(x => x.value === value));
    };

    const handleSubmit = () => {
        if(seletedItem)
        importHandler(seletedItem?.value)
    }

    if(seletedItem){
        return (
            <div className={`${layout.column} ${layout.justifySpaceAround}`} style={{height: "100%"}}>
                <Form>
                    <div className={`${layout.column} ${layout.justifySpaceAround}`}>
                        <FloatingLabelSelect onChange={(e) => handleTemplateSelected(e.target.value)} label={"Select Template to import"} options={options} value={seletedItem.value} required={true}></FloatingLabelSelect>
            
                        <div className={`${layout.rowSpan} ${layout.justifyFlexEnd} ${layout.padding10}`}>
                            <Button clickHandler={handleSubmit} autoDisable>Create Activity</Button>
                        </div>
                    </div>
                </Form>
            </div>
          );
    }
    else{
        return <></>
    }

 
};

export default ImportTemplate;