import React, { ReactNode, useState, useEffect } from 'react';
//CSS
import styles from './Button.module.css';

type ButtonProps = {
    id?: string;
    clickHandler?: () => void;
    className?: string;
    border?: string;
    children?: ReactNode;
    colour?: string | null;
    textColour?: string | null;
    size?: "Small" | "Icon" | null;
    autoDisable?: boolean; // Time in milliseconds
};

const Button = ({
    id,
    border,
    clickHandler,
    className,
    children,
    colour,
    textColour,
    size,
    autoDisable,
}: ButtonProps) => {
    const [isDisabled, setIsDisabled] = useState(false);

    const style = {
        ...(colour && { backgroundColor: colour }),
        ...(textColour && { color: textColour }),
        ...(border && { border: `1px solid ${border}` }),
    };

    // Handle button click with auto-disable
    const handleClick = () => {
        if (clickHandler) clickHandler();

        if (autoDisable) {
            setIsDisabled(true); // Disable the button
            setTimeout(() => {
                setIsDisabled(false); // Re-enable after autoDisable duration
            }, 3500);
        }
    };

    return (
        <button
            onClick={handleClick}
            disabled={isDisabled}
            className={
                `${styles.container} ${size === "Small" ? styles.small : size === "Icon" ? styles.icon : ""} ${
                    isDisabled ? styles.disabled : ""
                } ${className || ""}`
            }
            style={style}
            id={id}
        >
            {children}
        </button>
    );
};

export default Button;
