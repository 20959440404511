import React from "react";
import styles from "./FloatingLabelSelect.module.css";
import { FloatingLabelSelectOption } from "../../../../Types/Form/FloatingLabelSelectOption";

type FloatingLabelSelectProps = {
  label: string;
  options: FloatingLabelSelectOption[];
  required?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  value: string; // Make value a required prop to ensure it is controlled
};

const FloatingLabelSelect: React.FC<FloatingLabelSelectProps> = ({
  label,
  options,
  required = false,
  onChange,
  value, // Accept the controlled value from the parent
}) => {
  return (
    <div className={styles.floatingLabelSelect}>
      <label className={styles.floatingLabel}>
        {label}
        {required && <span className={styles.asterisk}>*</span>}
      </label>
      <select
        className={styles.select}
        value={value} // Controlled value
        onChange={onChange} // Trigger the parent's onChange handler
        required={required}
      >
        {/* Placeholder option */}
        <option value="" disabled hidden>
          Select an option
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default FloatingLabelSelect;
