import { axiosInstance } from '../../Authentication/AxiosInterceptor'; // Adjust the import path
import { ImprovementPlanDisplayInfo } from '../../Types/AreaDisplayInfo/ImprovementPlanDisplayInfo';
import { CreateEvaluateImprovementRequest } from '../../Types/Evaluate/CreateEvaluateImprovementRequest';
import { ImproveArea } from '../../Types/Improve/ImproveArea'; // Create and adjust path to types if needed
import { Improvement } from '../../Types/Improve/Improvement';

export const GetImproveAreaById = async (id: string): Promise<ImproveArea> => {
  try {
    const response = await axiosInstance.get<ImproveArea>(`GetImproveAreaById`, {
      params: { id },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching ImproveArea by ID:', error);
    throw error; // Allow caller to handle the error
  }
};

export const GetImprovementPlanDisplayInfoByClientAndUser = async (clientId: string, userId: string) : Promise<ImprovementPlanDisplayInfo[]> => {

    try {

        const response = await axiosInstance.get<ImprovementPlanDisplayInfo[]>(`/GetImprovementPlanDisplayInfoByClientAndUser/${clientId}/${userId}`)
        return response.data;
        
    } catch (error) {
        console.error('Error fetching GetImprovementPlanDisplayInfoByClientAndUser:', error);
        throw error; // Handle the error properly in application logic
    }


}
    

// Async Action to Call the API
export const CreateEvaluateImprovement = async (improvement: CreateEvaluateImprovementRequest) => {

    try {
      const response = await axiosInstance.post(`CreateEvaluateImprovement`, improvement);
      return response.data;
    } catch (error) {
      console.error('Error creating improvements:', error);
      throw error; // Handle the error properly in application logic
    }
};