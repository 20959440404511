import React, { ChangeEvent, ReactNode } from 'react';  
// CSS  
import styles from './TitledField.module.css';  
import layout from '../../../Modules/layout.module.css';  
import effects from '../../../Modules/effects.module.css';  
import Checkbox from '../Checkbox/Checkbox';  
  
type TitledFieldProps = {  
  title: string;  
  text: string;  
  poweredByAI?: boolean;  
  children?: ReactNode;  
  valueChanged?: (text: string) => void;  
  titleChanged?: (title: string) => void;  
  onSelectedHandler?: (selected: boolean) => void;  
};  
  
const TitledField = ({  
  title,  
  text,  
  poweredByAI,  
  children,  
  valueChanged,  
  titleChanged,  
  onSelectedHandler,  
}: TitledFieldProps) => {  
  // Handle the change event and extract the text value  
  const handleTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {  
    if (valueChanged) {  
      valueChanged(event.target.value);  
    }  
  };  
  
  // Handle the change event for the title input  
  const handleTitleChange = (event: ChangeEvent<HTMLInputElement>) => {  
    if (titleChanged) {  
      titleChanged(event.target.value);  
    }  
  };  
  
  return (  
    <div className={`${layout.column} ${layout.relative}`}>  
      {/*<div className={`${layout.topRight} ${styles.sparkle}`}>  
        <img src='/img/sparkle-multicoloured.svg' alt="sparkle" />  
      </div>*/}
      <div className={layout.row}>  
        {onSelectedHandler ? <Checkbox clickHandler={onSelectedHandler}/> : <></>}  
        <input  
          className={styles.title}  
          value={title}  
          placeholder={"Enter title..."}  
          onChange={handleTitleChange}  
        />  
        <p className={layout.flex1}></p>  
      </div>  
      <div className={`${styles.content} ${effects.shadow}`}>  
        {children ?? <textarea onChange={handleTextChange} value={text} placeholder='Enter field description...'/>}  
      </div>  
    </div>  
  );  
};  
  
export default TitledField;  
