import { queries } from "@testing-library/react";
import { ICompletionStatus } from "../ICompletionStatus";
import { IQuestionnaireQuestion } from "./IQuestionnaireQuestion";
import { IQuestionnaireSectionResults } from "./IQuestionnaireSectionResults";
import { IQuestionnaireSection } from "./IQuestionnaireSection";
import { IGrade } from "../IGrade";
import { IDynamicField, SpecialField } from "./IDynamicField";

export type ICriteriaItem = {
    id: string,
    title: string,
    description: string,
    active: boolean,
    questions: IQuestionnaireQuestion[],
    grade: IGrade,
    tags: string[],
    fields: IDynamicField[]
}

export const getQuestionIndex = (criteriaItem: ICriteriaItem, question: IQuestionnaireQuestion): number => {
    return criteriaItem.questions.findIndex((q) => q.id === question.id);
};

export const getSectionIndex = (sections: IQuestionnaireSection[], question: IQuestionnaireQuestion): number => {
    return sections.findIndex((section) =>
        getCriteriaIndex(section, question) !== -1
    );
};

export const getSectionIndexById = (sections: IQuestionnaireSection[], sectionId: string): number => {
    return sections.findIndex((section) =>
        section.id === sectionId
    );
};

export const getCriteriaIndex = (section: IQuestionnaireSection, question: IQuestionnaireQuestion): number => {
    return section.criteria.findIndex((criteriaItem) => 
        getQuestionIndex(criteriaItem, question) !== -1
    );
};

export const getCriteriaIndexById = (section: IQuestionnaireSection, criteriaId: string): number => {
    return section.criteria.findIndex((criteriaItem) => 
        criteriaItem.id === criteriaId
    );
};

export const getAreasForImprovement = (fields: IDynamicField[]): IDynamicField[] => {
    return fields.filter(field => field.specialField === SpecialField.AreasForImprovement);
};
