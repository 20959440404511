import React, { useState } from "react";
//CSS
import layout from "../../../Modules/layout.module.css";
import styles from "./CreateImprovementQuestionSection.module.css";
import CreateImprovementQuestionSectionOption from "./CreateImprovementQuestionSectionOption/CreateImprovementQuestionSectionOption";
import { ICriteriaItem } from "../../../Types/Products/ICriteriaItemData";
import { CreateEvaluateImprovementRequest } from "../../../Types/Evaluate/CreateEvaluateImprovementRequest";
import {
  IDynamicField,
  SpecialField,
} from "../../../Types/Products/IDynamicField";
//Components
//Data

type CreateImprovementQuestionSectionProps = {
  title: string;
  id: string;
  criteria: ICriteriaItem[];
  openOnLoad?: boolean;
  improvementData: CreateEvaluateImprovementRequest;
  setImprovementData: React.Dispatch<
    React.SetStateAction<CreateEvaluateImprovementRequest>
  >;
};

const CreateImprovementQuestionSection = ({
  title,
  id: sectionId, // Renamed for clarity
  criteria,
  openOnLoad,
  improvementData,
  setImprovementData,
}: CreateImprovementQuestionSectionProps) => {
  const [expanded, setExpanded] = useState<Boolean>(openOnLoad ? true : false);
  const [areasForImprovement, setAreasForImprovement] = useState<any>();

  const getAreasForImprovement = (fields: IDynamicField[]): IDynamicField[] => {
    return fields.filter(
      (field) => field.specialField === SpecialField.AreasForImprovement
    );
  };

  const getAreasForImprovementData = (id: string): IDynamicField | null => {
    const criteriaItem = criteria.find((c) => c.id === id);
    if (!criteriaItem) return null; // If no criteria item is found, return null

    // Filter out fields that have the `specialField` set to `AreasForImprovement`
    const areas = getAreasForImprovement(criteriaItem.fields);

    // Return the first matching area or null if none found
    return areas.length > 0 ? areas[0] : null;
  };

  const questionSelectionHandler = (
    questionId: string,
    isSelected: boolean
  ) => {
    setImprovementData((prevState) => {
      // Ensure questionGroups is not null
      const questionGroups = prevState.questionGroups || [];

      // Find the index of the QuestionGroupReference for this section
      const groupIndex = questionGroups.findIndex(
        (group) => group.questionGroupId === sectionId
      );

      // Clone the questionGroups array to safely update it
      const updatedQuestionGroups = [...questionGroups];

      if (groupIndex > -1) {
        // Group exists for this sectionId
        const group = updatedQuestionGroups[groupIndex];

        if (isSelected) {
          // Add the questionId if not already in the SelectedQuestions array
          if (!group.selectedQuestions.includes(questionId)) {
            group.selectedQuestions = [...group.selectedQuestions, questionId];
          }
        } else {
          // Remove the questionId from SelectedQuestions
          group.selectedQuestions = group.selectedQuestions.filter(
            (id) => id !== questionId
          );

          // If no questions remain in the group, remove the group from the array
          if (group.selectedQuestions.length === 0) {
            updatedQuestionGroups.splice(groupIndex, 1); // Remove the empty group
          }
        }
      } else if (isSelected) {
        // Group doesn't exist for this sectionId, create a new one
        updatedQuestionGroups.push({
          questionGroupId: sectionId,
          selectedQuestions: [questionId],
        });
      }

      return {
        ...prevState,
        questionGroups: updatedQuestionGroups,
      };
    });
  };

  return (
    <div className={`${layout.column} ${styles.container}`}>
      <div
        className={`${layout.rowSpan} ${styles.top}`}
        onClick={() => setExpanded(!expanded)}
      >
        <h2>{title ? title : "Question Section Title"}</h2>
        <div className={`${styles.arrow} ${expanded && styles.expanded}`}>
          <img src="/img/down-arrow.svg" />
        </div>
      </div>
      {expanded && (
        <div className={styles.body}>
          {criteria.map((c) => {
            return (
              <CreateImprovementQuestionSectionOption
                key={c.id}
                title={c.title}
                id={c.id}
                onQuestionSelected={questionSelectionHandler}
                areasForImprovement={getAreasForImprovementData(c.id)} // Pass single field or null
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CreateImprovementQuestionSection;
