import React, { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './SectionsDrawer.module.css';
import { IProductQuestionnaire } from '../../Types/Products/IProductQuestionnaire';
import { IDrawerItem, IDrawerSection } from '../../Types/Drawer/ISectionsDrawerItem';
import { DrawerProvider, useDrawer } from './DrawerContext';
import SectionsDrawerItem from './SectionsDrawerItem';

type SectionsDrawerProps = {  
  sections: IDrawerSection[],  
  basePath: string,  
  initialActiveItem?: string,  
  onClickHandler?: (item: IDrawerItem) => void  
};  
  
const SectionsDrawer: React.FC<SectionsDrawerProps> = ({ sections, basePath, initialActiveItem, onClickHandler }) => {  
  const { activeSection, setActiveSection } = useDrawer();  
  
  useEffect(() => {  
    if (initialActiveItem) {  
      const section = sections.find(section => section.items.some(item => item.id === initialActiveItem));  
      if (section) {  
        setActiveSection(section);  
      }  
    }  
  }, [initialActiveItem, sections, setActiveSection]);  
  
  return (  
    <div>  
      {sections.map((section, index) => (  
        <div key={index} onClick={section === activeSection ? undefined : () => setActiveSection(section)}>  
          <SectionsDrawerItem  
            key={section.id}  
            section={section}  
            active={section === activeSection}  
            basePath={basePath}  
            initialActiveItem={initialActiveItem ?? section.items[0]?.id}  
            onClickHandler={onClickHandler}  
          />  
        </div>  
      ))}  
    </div>  
  );  
};  
  
export default SectionsDrawer;  