import React from 'react';
import styles from './FloatingLabelInput.module.css';

type FloatingLabelInputProps = {
  placeholder?: string;
  label: string;
  required?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string; // Controlled value
};

const FloatingLabelInput = ({
  placeholder = '',
  label,
  required = false,
  onChange,
  value, // Controlled by props
}: FloatingLabelInputProps) => {
  // Handle the onChange event and pass it up to the parent
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(e); // Call the parent-provided onChange if it exists
  };

  return (
    <div className={styles.floatingLabelInput}>
      <label className={styles.floatingLabel}>
        {label}
        {required && <span className={styles.asterisk}>*</span>}
      </label>
      <input
        className={styles.input}
        type="text"
        placeholder={placeholder}
        value={value} // Display value from parent
        onChange={handleChange} // Pass changes to the parent
        required={required}
      />
    </div>
  );
};

export default FloatingLabelInput;
