import React, { useState, ChangeEvent, KeyboardEvent } from 'react';  
import styles from './CreationBar.module.css';  
import layout from '../../../../Modules/layout.module.css';  
import effects from '../../../../Modules/effects.module.css';  
  
type CreationBarProps = {  
  onCreateHandler: (title: string) => void;  
  placeholder: string;  
  noTextMessage: string; // will be displayed to warn the user that the field is required  
};  
  
const CreationBar = ({ onCreateHandler, placeholder, noTextMessage }: CreationBarProps) => {  
  const [inputValue, setInputValue] = useState<string>('');  
  const [warningMessage, setWarningMessage] = useState<string>('');  
  const [animate, setAnimate] = useState<boolean>(false);  
  
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {  
    setInputValue(event.target.value);  
  };  
  
  const handleCreateClick = () => {  
    if (inputValue.trim() === '') {  
      setWarningMessage(noTextMessage);  
      setAnimate(true);  
      setTimeout(() => setAnimate(false), 500); // Remove animation class after animation duration  
    } else {  
      setWarningMessage('');  
      onCreateHandler(inputValue);  
      setInputValue(''); // Clear the input after creation  
    }  
  };  
  
  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {  
    if (event.key === 'Enter') {  
      handleCreateClick();  
    }  
  };  
  
  return (  
    <div className={`${layout.row} ${effects.shadow}`}>  
      <button className={styles.createButton} onClick={handleCreateClick}>  
        <img src='/img/archive.svg' alt='Create' />  
        <p>Create</p>  
      </button>  
      <input  
        className={`${styles.createInput} ${animate ? `${styles.shake} ${styles.pulse}` : ''}`}  
        placeholder={placeholder}  
        value={inputValue}  
        onChange={handleInputChange}  
        onKeyDown={handleKeyDown}  
      />  
      {warningMessage && <p className={styles.warningMessage}>{warningMessage}</p>}  
    </div>  
  );  
};  
  
export default CreationBar;  